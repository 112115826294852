
























































import { Vue, Component, Prop } from 'vue-property-decorator'
import { chatImportStore } from '@/store'

@Component({
  components: {
    BaseLinearProgress: () => import('@/components/UI/BaseLinearProgress.vue'),
    BaseBtn: () => import('@/components/UI/BaseBtn.vue'),
    IconFile: () => import('@/components/UI/icons/IconFile.vue'),
    IconXCircle: () => import('@/components/UI/icons/IconXCircle.vue'),
  },
})
export default class LoadingZone extends Vue {
  @Prop({
    required: true,
    type: Number,
  }) private readonly progressValue!: number

  @Prop({
    required: true,
    type: String,
  }) private readonly progressLabel!: string

  @Prop({
    required: true,
    type: String,
  }) private readonly fileName!: string

  @Prop({
    type: Boolean,
  }) private readonly hasErrorLoading!: boolean

  get title () {
    if (this.hasErrorLoading) {
      return this.$t('import.step1.upload.loading.titleError').toString()
    }
    return this.progressValue >= 1
      ? this.$t('import.step1.upload.loading.titleDone').toString()
      : this.$t('import.step1.upload.loading.title').toString()
  }

  private async cancel () {
    await chatImportStore.actions.cancelImportFile()
  }

  get cancelText () {
    return this.progressValue >= 1
      ? this.$t('import.step1.upload.remove').toString()
      : this.$t('import.step1.upload.cancel').toString()
  }
}
