var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"loading-zone fit col column"},[_c('q-item-label',{staticClass:"loading-zone-title",class:{
      'loading-zone-title__error': _vm.hasErrorLoading
    }},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('q-item',{staticClass:"q-pa-none items-center q-mt-lg"},[_c('div',{staticClass:"td-rounded-8 loading-zone-file q-mr-md"},[_c('q-icon',{staticClass:"loading-zone-file__icon",attrs:{"size":"24px","color":"base-brand"}},[_c('IconFile')],1)],1),_c('BaseLinearProgress',{staticClass:"fit",attrs:{"value":_vm.progressValue,"title":_vm.fileName,"progress":_vm.progressLabel,"has-error":_vm.hasErrorLoading}}),(!_vm.hasErrorLoading)?_c('q-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        text: _vm.cancelText,
        options: {
          backgroundColor: "var(--bg-background)",
          color: "var(--font-text)"
        }
      }),expression:"{\n        text: cancelText,\n        options: {\n          backgroundColor: `var(--bg-background)`,\n          color: `var(--font-text)`\n        }\n      }"}],staticClass:"cursor-pointer",attrs:{"size":"24px","color":"base-back-dark q-ml-md"},on:{"click":_vm.cancel}},[_c('IconXCircle')],1):_c('BaseBtn',{staticClass:"q-ml-md loading-zone-repeat",attrs:{"label":_vm.$t('import.step1.upload.repeatLoad').toString(),"text-color":"font-text-simple-button","color":"button-simple-static","padding":"xs sm"},on:{"click":function () { return _vm.$emit('repeat'); }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }